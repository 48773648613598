<template>
    <div class="container py-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Admin Dashboard</router-link></li>
                <li class="breadcrumb-item"><router-link to="/adminskinstories">Skin stories</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Skin story</li>
            </ol>
        </nav>
        <div class="row justify-content-between">
            <div class="col-12">
                <h1>Skin story</h1>

                <form @submit.prevent="handleSubmit">

                    <div class="mb-3 row">
                        <label for="name" class="col-sm-2 col-form-label">Name:</label>
                        <div class="col-sm-10">
                            <input v-model="skinstory.name" type="text" class="form-control" id="name" placeholder="name" required>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="url" class="col-sm-2 col-form-label">url:</label>
                        <div class="col-sm-10">
                            <input v-model="url" type="text" class="form-control" id="url" disabled>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="image" class="col-sm-2 col-form-label">Image:</label>
                        <div class="col-sm-6">
                            <div class="btn-group">
                                <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">Select image</button>
                                <div class="dropdown-menu" style="max-height:500px; overflow:scroll; width:550px;">
                                    <a v-for="(image, index) in images" :key="index" href="#" class="dropdown-item" @click.stop.prevent="skinstory.image= '/images/' + image">
                                        <div style="display: inline-flex; align-items: center;">
                                            <div class="d-flex flex-wrap align-items-center" style="width: 155px; border: 1px silver solid; background:white;">
                                                <img :src="'/images/' + image" style="object-fit: scale-down; height:150px; width:150px; padding:0px;" />
                                            </div>
                                            <div class="ms-2">{{image}}</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 align-content-end">
                            <div class="d-flex flex-wrap align-items-center align-content-center float-end" style="border: 1px solid silver; padding: 2px; width: 155px; height: 155px;">
                                <img v-if="skinstory.image" :src="skinstory.image" style="object-fit: scale-down; height:150px; width:150px;" />
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="shortDescription" class="col-sm-2 col-form-label">Description:</label>
                        <div class="col-sm-10">
                            <textarea v-model="skinstory.shortDescription" class="form-control" id="shortDescription" rows="3" placeholder="short description" required></textarea>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="author" class="col-sm-2 col-form-label">Author:</label>
                        <div class="col-sm-10">
                            <input v-model="skinstory.author" class="form-control" id="author" placeholder="author" required>
                        </div>
                    </div>

                    <Editor :modelValue=skinstory.content @update:modelValue="skinstory.content = $event" />

                    <div class="mb-3 row py-3">
                        <div class="col-sm-10 d-sm-inline-flex">

                        </div>
                        <div class="col-sm-2 d-sm-flex justify-content-sm-end">
                            <div v-if="loading" class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div v-else>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="skinstory.isDraft">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">This is a draft</label>
                                </div>
                                <button class="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Editor from '@/components/Editor.vue'
    import axios from "axios";
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';

    const options = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    export default {
        name: "AdminSkinStory",
        components: { Editor },
        data() {
            return {
                loading: false,
                skinstory: {
                    name: '',
                    content: 'write yout text here...',
                    image: '',
                    author: '',
                    shortDescription:''
                },
                images: []
            }
        },
        methods: {
            getData() {
                if (this.$route.query.id) {
                    axios.get(process.env.VUE_APP_URL + `api/skinStories/id/${this.$route.query.id}`, options)
                        .then(response => {
                            this.skinstory = response.data;
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
                        .finally(() => {
                            this.loaded = true;
                        });
                }
            },
            handleSubmit() {
                this.loading = true;

                this.skinstory.url = this.url;
                if (!this.skinstory.id) {
                    axios.post(process.env.VUE_APP_URL + 'api/skinstories', this.skinstory, options)
                        .then(response => {
                            this.skinstory = response.data;
                            this.$router.push('adminskinstories');
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$toast(error.message, toastOptions.error);
                        });
                }
                else {
                    axios.put(process.env.VUE_APP_URL + `api/skinstories/${this.skinstory.id}`, this.skinstory, options)
                        .then(response => {
                            this.skinstory = response.data;
                            this.$router.push('adminskinstories');
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$toast(error.message, toastOptions.error);
                        });
                }
            },
        },
        watch: {
            '$route.query.id': {
                handler: function () {
                    if (this.$route.query.id) {
                        this.getData();
                    }
                },
                deep: true,
                immediate: true
            },
            'skinstory.name': {
                handler: function () {

                }
            }
        },
        computed: {
            url: function () {
                return this.skinstory.name.replace(/[^\w\s]/gi, '').replaceAll(" ", "-").toLowerCase().substring(0, 60)
            }
        },
        beforeMount() {
            axios.get(process.env.VUE_APP_URL + `api/imagemanager`)
                .then(response => {
                    this.images = response.data;
                    console.log(this.images);
                })
                .catch(error => {
                    this.$toast(error.message, toastOptions.error);
                })
                .finally(() => {
                    this.loaded = true;
                });
        }
    }
</script>
